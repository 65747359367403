import React, { useEffect } from 'react';
// import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';

const Hero = () => {

    useEffect(() => {
        const loadParticlesJS = async () => {
          const particlesScript = document.createElement('script');
          particlesScript.src =
            'https://cdnjs.cloudflare.com/ajax/libs/particles.js/2.0.0/particles.min.js';
          particlesScript.async = true;
          document.body.appendChild(particlesScript);
    
          particlesScript.onload = () => {
            window.particlesJS('particles-js', {
              particles: {
                number: {
                  value:100,
                  density: {
                    enable: true,
                    value_area: 700,
                  },
                },
                color: {
                  value: '#1E3A8A',
                },
                shape: {
                  type: 'circle',
                  stroke: {
                    width: 0,
                    color: '#000000',
                  },
                  polygon: {
                    nb_sides: 5,
                  },
                  image: {
                    width: 100,
                    height: 100,
                  },
                },
                opacity: {
                  value: 0.5,
                  random: false,
                  anim: {
                    enable: false,
                    speed: 1,
                    opacity_min: 0.1,
                    sync: false,
                  },
                },
                size: {
                  value: 5,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 40,
                    size_min: 0.1,
                    sync: false,
                  },
                },
                line_linked: {
                  enable: true,
                  distance: 150,
                  color: '#1E3A8A',
                  opacity: 0.4,
                  width: 1,
                },
                move: {
                  enable: true,
                  speed: 2,
                  direction: 'none',
                  random: false,
                  straight: false,
                  out_mode: 'out',
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200,
                  },
                },
              },
              interactivity: {
                detect_on: 'canvas',
                events: {
                  onhover: {
                    enable: true,
                    mode: 'repulse',
                  },
                  onclick: {
                    enable: true,
                    mode: 'push',
                  },
                  resize: true,
                },
                modes: {
                  grab: {
                    distance: 400,
                    line_linked: {
                      opacity: 1,
                    },
                  },
                  bubble: {
                    distance: 400,
                    size: 40, // Set bubble size to 40
                    duration: 2,
                    opacity: 8,
                    speed: 3,
                  },
                  repulse: {
                    distance: 100,
                  },
                  push: {
                    particles_nb: 4,
                  },
                  remove: {
                    particles_nb: 2,
                  },
                },
              },
              retina_detect: true,
              config_demo: {
                hide_card: false,
                background_color: '#1E3A8A',
                background_image: '',
                background_position: '50% 50%',
                background_repeat: 'no-repeat',
                background_size: 'cover',
              },
            });
          };
        };
    
        loadParticlesJS();
    
        // Clean up the script tag when the component is unmounted
        return () => {
          const particlesScript = document.querySelector('script[src*="particles.min.js"]');
          if (particlesScript) {
            document.body.removeChild(particlesScript);
          }
        };
      }, []);
    
    
    return (
        <>
            <div className="hero" id='hero'>
                <div id="particles-js" style={{ position: 'relative', height: '100vh' }}>
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
                        <span className='fw-bold my-5'> Conversational AI</span>
                        <h1 className=" font-bold text-blue-900 mt-4">Where Chat Meets AI</h1>
                        <div className="text-lg text-secondary  tracking-tight mt-4 ">We Design, Develop, Deploy and Integrate Hybrid Conversational AI Solutions.</div>
                        <div className="mb-4 space-x-0 md:space-x-2 mt-4">
                            <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 mt-4 mb-1 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                Learn more
                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link>
                            <Link to="/contact" className="text-white bg-gray-500 hover:bg-gray-400 inline-flex items-center justify-center w-full px-6 py-3 mt-4 mb-1 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                Get Started
                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link>
                        </div>   
                    </div>
                </div>
                
                <NavBar />
            
                {/* <div className='hero-section '>
                    <div className='row w-100 justify-content-center text-center mt-5'>
                        <div className='col-md-12 mt-5@'>
                            <span className='fw-bold mb-5'> Conversational AI</span>
                            <h1 className=" font-bold text-blue-900 mt-4">
                                Where Chats Meet AI
                            </h1>
                            <div className="text-lg text-secondary  tracking-tight mt-4 ">We Design, Develop, Deploy and Integrate Hybrid Conversational AI Solutions.</div>
                            <div className="mb-4 space-x-0 md:space-x-2 mt-4">
                                    <Link to="/workinprogress" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                        Learn more
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                    <Link to="/contact" className="text-white bg-gray-500 hover:bg-gray-400 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                        Get Started
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                    <a href="#_" className="inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg text-white bg-gray-500 hover:bg-gray-400 shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                        Learn More
                                        <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg>
                                    </a>
                            </div>
                        </div>
                    </div>
                </div> */}

                
              
            </div>
        </>
    )
}

export default Hero;
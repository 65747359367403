import React, { useState } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';

const Build = () => {
  const [showMore1, setShowMore1] = useState(false);
  const [showMore2, setShowMore2] = useState(false);

  const toggleShowMore1 = () => {
    setShowMore1(!showMore1);
  };

  const toggleShowMore2 = () => {
    setShowMore2(!showMore2);
  };

  return (
    <div className='build'>
      <Container>
        <div className="my-4 py-4 upper">
          <h1 className="my-2 text-center fw-bold text-blue-900 uppercase">why us</h1>
          <p className="mt-2 text-muted lead mx-12 text-center text-secondary">We are deeply committed to the growth and success of our clients.</p>
        </div>
        <Row className='justify-content-around align-items-top'>
          <Col md={5}>
            <div className='d-flex align-items-center mb-4'>
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" className='text-blue-900 fill-current'><path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path><path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path></svg>
        
              <h1 className='fw-bold text-left ms-3'>WE BUILD</h1>
            </div>
            <div>
              <p>{showMore1 ? "With over over 5 years of hands-on experience in Conversational AI and design, and we truly understand the latest trends and solutions in the IT industry. Our expertise allows us to offer personalized recommendations and strategies tailored to your specific needs, aiming to enhance your operations, cut down on costs, and boost overall efficiency." : "With over over 5 years of hands-on experience in Conversational AI and design..."}
              </p>
              <Button className='readBtn' variant="link" onClick={toggleShowMore1}>
                {showMore1 ? 'Read less' : 'Read more'}
              </Button>
            </div>
          </Col>
          <Col md={5}>
            <div className='d-flex align-items-center mb-4'>
              {/* Replace the path with the desired SVG path for the second icon */}
              <svg xmlns="http://www.w3.org/2000/svg"  width="50" height="50" viewBox="0 0 640 512" className='text-blue-900 fill-current'><path d="M323.4 85.2l-96.8 78.4c-16.1 13-19.2 36.4-7 53.1c12.9 17.8 38 21.3 55.3 7.8l99.3-77.2c7-5.4 17-4.2 22.5 2.8s4.2 17-2.8 22.5l-20.9 16.2L512 316.8V128h-.7l-3.9-2.5L434.8 79c-15.3-9.8-33.2-15-51.4-15c-21.8 0-43 7.5-60 21.2zm22.8 124.4l-51.7 40.2C263 274.4 217.3 268 193.7 235.6c-22.2-30.5-16.6-73.1 12.7-96.8l83.2-67.3c-11.6-4.9-24.1-7.4-36.8-7.4C234 64 215.7 69.6 200 80l-72 48V352h28.2l91.4 83.4c19.6 17.9 49.9 16.5 67.8-3.1c5.5-6.1 9.2-13.2 11.1-20.6l17 15.6c19.5 17.9 49.9 16.6 67.8-2.9c4.5-4.9 7.8-10.6 9.9-16.5c19.4 13 45.8 10.3 62.1-7.5c17.9-19.5 16.6-49.9-2.9-67.8l-134.2-123zM16 128c-8.8 0-16 7.2-16 16V352c0 17.7 14.3 32 32 32H64c17.7 0 32-14.3 32-32V128H16zM48 320a16 16 0 1 1 0 32 16 16 0 1 1 0-32zM544 128V352c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V144c0-8.8-7.2-16-16-16H544zm32 208a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z"/></svg>
              <h1 className='fw-bold text-left ms-3'>WE COLLABORATE</h1>
            </div>
            <div className=''>
              <p className='mt-0'>{showMore2 ? "We collaborates with your tech team to scale existing software applications or design customized solutions that simplify your everyday processes." : "We collaborates with your tech team to scale existing software applications or design customized solutions..."}
              </p>
              <Button className='readBtn' variant="link" onClick={toggleShowMore2}>
                {showMore2 ? 'Read less' : 'Read more'}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Build;

import React from 'react'
import { Col, Container, Row ,Nav,
    // TAb
} from 'react-bootstrap'
import img from '../images/web.svg'; 
import img2 from '../images/app.svg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';

import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';

const Service = () => {
  return (
    <div className='services' id='services'>
        <Container >
            <div className="my-4 py-4 upper">
                <h1 className="my-2 text-center fw-bold text-blue-900 uppercase">services</h1>
                <p className="mt-2 text-muted lead mx-12 text-center text-secondary">
                Explore our comprehensive range of services tailored to meet your business needs.
                    </p>
            </div>

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row className='tab-img align-items-center'>
                    <Col sm={7}>
                    <Nav variant="pills" className="flex-column">
                        <Row>
                            <Col md={6}>
                            <Nav.Item >
                            <Nav.Link eventKey="first" className='box  bg-white rounded-3 shadow-sm text-center mb-3 p-2' >
                                <div className='img-tab'>
                                    <img alt="card img" src={img2} />
                                </div>
                                <h6 className="font-semibold mb-4 ">Conversational AI</h6>
                            </Nav.Link>
                        </Nav.Item>
                            </Col>

                            

                            <Col md={6}>
                            <Nav.Item>
                                <Nav.Link eventKey="third" className='box bg-white rounded-3 shadow-sm text-center mb-3 p-2' >
                                    <div className='img-tab'>
                                        <img alt="card img" src={img} />
                                    </div>
                                    <h6 className="font-semibold mb-4 ">Python Development</h6>
                                </Nav.Link>
                            </Nav.Item>
                            </Col>

                            <Col md={6}>
                            <Nav.Item>
                            <Nav.Link eventKey="second" className='box bg-white rounded-3 shadow-sm text-center mb-3 p-2' >
                            <div className='img-tab'>
                                    <img alt="card img" src={img3} />
                                </div>
                                <h6 className="font-semibold mb-4 ">DevOps and Cloud Solutions</h6>
                            </Nav.Link>
                        </Nav.Item>
                            </Col>

                            <Col md={6}>
                            <Nav.Item>
                                <Nav.Link eventKey="fourth" className='box bg-white rounded-3 shadow-sm text-center mb-3 p-2' >
                                <div className='img-tab'>
                                        <img alt="card img" src={img4} />
                                    </div>
                                    <h6 className="font-semibold mb-4 ">Natural Language Processing</h6>
                                </Nav.Link>
                            </Nav.Item>
                            </Col>
                        </Row>
                        </Nav>
                    </Col>
                    <Col sm={5}>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <div>
                                <h2 className=" font-semibold my-4 text-blue-900">Conversational AI</h2>
                                <p className="text-md font-medium">Transforming businesses' customer interactions, we harness the power of Conversational AI to build interactive chatbots, virtual assistants, and voice-enabled applications. Seamlessly integrated into various industries, our solutions revolutionize customer experiences, ensuring efficient and personalized communication.</p>
                            </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="second">
                            <div>
                                <h2 className="font-semibold my-4 text-blue-900">DevOps and Cloud Solutions</h2>
                                <p>With our cutting-edge DevOps practices and cloud solutions, we optimize workflows, enhance collaboration, and deploy applications securely and efficiently in the cloud environment. Our team ensures seamless integration, automation, and scalability, empowering your business to thrive in the digital landscape.</p>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <div>
                                <h2 className="font-semibold my-4 text-blue-900">Python Development</h2>
                                <p>Leveraging the versatility of Python, our expert developers craft scalable, robust, and efficient applications across diverse domains. From web development to data analysis, our Python expertise knows no bounds. We provide tailor-made solutions to suit your unique business needs.</p>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">
                            <div>
                                <h2 className="font-semibold my-4 text-blue-900">Natural Language Processing</h2>
                                <p className="text-md font-medium">Unleashing the potential of NLP, we enable machines to comprehend, interpret, and respond to human language effectively. Our NLP solutions drive enhanced communication and understanding, empowering your applications to process and interpret text-based data seamlessly.</p>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

      </Container>
    </div>
  )
}

export default Service



    

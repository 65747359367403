import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import {useDocTitle} from '../components/CustomHook';
// import axios from 'axios';
// import emailjs from 'emailjs-com';
import Notiflix from 'notiflix';

var axios = require('axios');

const Contact = () => {
    useDocTitle('ConvSol - Send us a message')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])

    const clearErrors = () => {
        setErrors([])
    }

    const clearInput = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    const sendEmail = (e) => {
        e.preventDefault();
        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';
        
        var data = JSON.stringify({
            "first_name": firstName,
            "last_name": lastName,
            "contact": phone,
            "email": email,
            "message": message
          });

        // let fData = new FormData();
        // fData.append('first_name', firstName)
        // fData.append('last_name', lastName)
        // fData.append('email', email)
        // fData.append('phone_number', phone)
        // fData.append('message', message)

        // axios({
        //     method: "post",
        //     url: "http://127.0.0.1:8000/send-email/", 
        //     // process.env.REACT_APP_CONTACT_API,
        //     data: data,
        //     headers: { 
        //         'Content-Type': 'application/json'
        //       }
        //     // headers: {
        //     //     'Content-Type':  'multipart/form-data'
        //     // }
        // })

        var config = {
            method: 'post',
            url: 'https://contact.convsol.com/send-email/',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config).then(function (response) {
            console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          }).then(function (response) {
            document.getElementById('submitBtn').disabled = false;
            document.getElementById('submitBtn').innerHTML = 'send message';
            clearInput()
            //handle success
            Notiflix.Report.success(
                'Success',
                response.data.message,
                'Okay',
            );
        })
        .catch(function (error) {
            document.getElementById('submitBtn').disabled = false;
            document.getElementById('submitBtn').innerHTML = 'send message';
            console.log(error);
            //handle error
            // const { response } = error;
            // if(response.status === 500) {
            //     Notiflix.Report.failure(
            //         'An error occurred',
            //         response.data.message,
            //         'Okay',
            //     );
            // }
            // if(response.data.errors !== null) {
            //     setErrors(response.data.errors)
            // }
            
        });
    }
    return (
        <>
            <div>
                <NavBar />
            </div>
                <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 bg-gray-200 mb-5">
                    <div className="container mx-auto px-4 lg:px-20" data-aos="zoom-in">
                        <form onSubmit={sendEmail}>

                            <div className="w-full bg-info p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-sm">
                                <div  className='contact-heading'>
                                    <h1 className="font-bold text-center text-blue-900 uppercase ">Send us a message</h1>
                                </div>
                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-4">
                                        <div>
                                            <input 
                                                name="first_name" 
                                                className="w-full bg-gray-100 text-gray-900  p-3 rounded-lg focus:outline-none focus:shadow-outline shadow-sm"
                                                type="text" 
                                                placeholder="First Name*" 
                                                value={firstName}
                                                onChange={(e)=> setFirstName(e.target.value)}
                                                onKeyUp={clearErrors}
                                                required
                                            />
                                            {errors && 
                                                <p className="text-red-500 text-sm">{errors.first_name}</p>
                                            }
                                        </div>
                                        
                                        <div>
                                            <input 
                                                name="last_name" 
                                                className="w-full bg-gray-100 text-gray-900  p-3 rounded-lg focus:outline-none focus:shadow-outline shadow-sm"
                                                type="text" 
                                                placeholder="Last Name*"
                                                value={lastName}
                                                required
                                                onChange={(e)=> setLastName(e.target.value)}
                                                onKeyUp={clearErrors}
                                            />
                                            {errors && 
                                                <p className="text-red-500 text-sm">{errors.last_name}</p>
                                            }
                                        </div>

                                        <div>
                                            <input 
                                                name="email"
                                                className="w-full bg-gray-100 text-gray-900  p-3 rounded-lg focus:outline-none focus:shadow-outline shadow-sm"
                                                type="email" 
                                                placeholder="Email*"
                                                value={email}
                                                required
                                                onChange={(e)=> setEmail(e.target.value)}
                                                onKeyUp={clearErrors}   
                                            />
                                            {errors && 
                                                <p className="text-red-500 text-sm">{errors.email}</p>
                                            }
                                        </div>

                                        <div>
                                            <input
                                                name="phone_number" 
                                                className="w-full bg-gray-100 text-gray-900  p-3 rounded-lg focus:outline-none focus:shadow-outline shadow-sm"
                                                type="number" 
                                                placeholder="Phone*"
                                                value={phone}
                                                required
                                                onChange={(e)=> setPhone(e.target.value)}
                                                onKeyUp={clearErrors}
                                            />
                                            {errors && 
                                                <p className="text-red-500 text-sm">{errors.phone_number}</p>
                                            }
                                        </div>
                                </div>
                                <div className="my-4">
                                    <textarea 
                                        name="message" 
                                        placeholder="Message*" 
                                        className="w-full h-32 bg-gray-100 text-gray-900  p-3 rounded-lg focus:outline-none focus:shadow-outline shadow-sm"
                                        value={message}
                                        required
                                        onChange={(e)=> setMessage(e.target.value)}
                                        onKeyUp={clearErrors}
                                    ></textarea>
                                    {errors && 
                                        <p className="text-red-500 text-sm">{errors.message}</p>
                                    }
                                </div>
                                <div className="my-2 text-center">
                                    <button type="submit" id="submitBtn" className="uppercase text-sm font-bold tracking-wide bg-blue-900 hover:bg-blue-800 text-gray-100 py-3 px-4 rounded-lg
                                            focus:outline-none focus:shadow-outline">
                                        Send Message
                                    </button>

                                    
                                </div>
                        </div>
                        </form>
                        <div  className="w-full contact-box  side-box lg:w-2/6 px-8 py-6 ml-auto bg-blue-900 rounded-2xl">
                                    <div>
                                        <h5 className="text-warning fw-bold">Office Address</h5>
                                        <p className="text-light mt-3">Islamabad, Pakistan</p> 
                                    </div>
                                    <div>
                                        {/* <h5 className="text-warning fw-bold mt-4">Call Us</h5>
                                        <p className="text-light mt-3">08055384406</p>  */}
                                    </div>
                                    <div>
                                        <h5 className="text-warning fw-bold mt-4">Send an E-mail</h5>
                                        <p className="text-light mt-3">info@convsol.com</p> 
                                    </div>

                                    <div className="flex my-4 w-2/3 lg:w-1/2">
                                    {/* <a href="https://www.facebook.com/ENLIGHTENEERING/" target="_blank" rel="noreferrer" className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8 inline-block mx-1 text-center pt-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='fill-current font-black hover:animate-pulse'><path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path></svg>
                                    </a> */}
                                    <a href="https://www.linkedin.com/company/convsol-" target="_blank" rel="noreferrer" className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8 inline-block mx-1 text-center pt-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='fill-current font-black hover:animate-pulse'><circle cx="4.983" cy="5.009" r="2.188"></circle><path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path></svg>
                                    </a>
                                </div>
                        </div>
                    </div>
                </div>

                <Footer />
        </>


    )
}

export default Contact;